exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-curriculum-mdx": () => import("./../../../src/pages/curriculum.mdx" /* webpackChunkName: "component---src-pages-curriculum-mdx" */),
  "component---src-pages-exhibitions-tsx": () => import("./../../../src/pages/exhibitions.tsx" /* webpackChunkName: "component---src-pages-exhibitions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-56-56-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/56/56.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-56-56-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-ballet-b-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/ballet_b/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-ballet-b-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-bide-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/bide/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-bide-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-conecta-2-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/conecta2/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-conecta-2-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-dante-gaiman-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/dante-gaiman/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-dante-gaiman-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-danzantes-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/danzantes/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-danzantes-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-el-hombre-y-la-piedra-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/el_hombre_y_la_piedra/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-el-hombre-y-la-piedra-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-entre-plasticos-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/entre-plasticos/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-entre-plasticos-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-futbol-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/futbol/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-futbol-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-guardainfanta-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/guardainfanta/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-guardainfanta-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-mediterraneo-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/mediterraneo/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-mediterraneo-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-mediterraneo-salamandria-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/mediterraneo_salamandria/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-mediterraneo-salamandria-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-minimos-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/minimos/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-minimos-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-mira-como-se-contamina-tu-ojo-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/mira-como-se-contamina-tu-ojo/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-mira-como-se-contamina-tu-ojo-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-mujer-encajada-ii-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/mujer-encajada-ii/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-mujer-encajada-ii-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-plasticos-i-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/artwork/plasticos_i/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-artwork-plasticos-i-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-exhibitions-de-aqui-y-ahora-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/exhibitions/de-aqui-y-ahora/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-exhibitions-de-aqui-y-ahora-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-exhibitions-esperando-las-olas-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/exhibitions/esperando-las-olas/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-exhibitions-esperando-las-olas-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-press-cartel-spanish-golden-age-season-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/press/cartel-spanish-golden-age-season/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-press-cartel-spanish-golden-age-season-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-press-decorado-siglo-de-oro-readme-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/content/press/decorado_siglo_de_oro/README.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-content-press-decorado-siglo-de-oro-readme-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-src-pages-curriculum-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/solubeda/solubeda/src/pages/curriculum.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-solubeda-solubeda-src-pages-curriculum-mdx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */)
}

